import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './BlockWithTitle.css'

export function BlockWithTitle({ title, text }) {
  return (
    <div className={styles.component}>
      {title && <HeadingSm h='2'>{title}</HeadingSm>}
      <PortableTextComponent value={text} />
    </div>
  )
}
