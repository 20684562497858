import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'

export function useNormalizeLink({ item, location }) {
  return React.useMemo(
    () => {
      const isInternalLink = item?._type === 'internalLink'
      const href = isInternalLink && item?.ref
        ? determineDocumentPathSync({ document: item.ref, routeMap })
        : item?.href

      return {
        href: href || '#',
        label: item?.label || '',
        isActive: href === location.pathname,
        target: (isInternalLink) ? '_self' : '_blank'
      }
    },
    [item, location]
  )
}
