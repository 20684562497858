import { MediaWindow } from '/features/pageOnly/MediaWindow'

import styles from './MediaWindowsGrid.css'

export function MediaWindowsGrid({ images }) {
  return (
    <div className={styles.component}>
      <div className={styles.leftColumn}>
        <MediaWindow layoutClassName={styles.mediaWindowLayout} image={images[0]} aspectRatio={2 / 3} distanceOverride={35} />
        <MediaWindow layoutClassName={styles.mediaWindowLayout} image={images[1]} aspectRatio={3 / 2} distanceOverride={50} />
      </div>
      <div className={styles.rightColumn}>
        <MediaWindow layoutClassName={styles.mediaWindowLayout} image={images[2]} aspectRatio={2 / 3} distanceOverride={40} />
      </div>
    </div>
  )
}
