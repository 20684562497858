import { useLocation } from '@kaliber/routing'

import { useNormalizeLink } from '/machinery/useNormalizeLink'

import { BlockWithTitle } from '/features/buildingBlocks/BlockWithTitle'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { MediaWindowsGrid } from '/features/pageOnly/MediaWindowsGrid'

import styles from './BlockWithImages.css'

export function BlockWithImages({ title, text, cta, images }) {
  const location = useLocation()
  const [linkItem] = cta || []
  const normalizedLink = useNormalizeLink({ item: linkItem, location })
  const showCTA = normalizedLink?.href && normalizedLink?.label

  return (
    <section data-x='block-with-images' className={styles.component}>
      <div className={styles.textContainer}>
        <BlockWithTitle {...{ title, text }} />
        {showCTA && ( <ButtonLinkBlue
          href={normalizedLink.href}
          label={normalizedLink.label}
          dataX='link-in-block'
          layoutClassName={styles.buttonLayout}
        /> )}
      </div>
      {images && <div className={styles.imagesContainer}>
        <MediaWindowsGrid {...{ images }} />
      </div>}
    </section>
  )
}
